import './App.css';
import {useState, useEffect} from 'react';
import MainMint from './MainMint';
import NavBar from './NavBar';

function App() {

    const [accounts, setAccounts] = useState([])

    return (
        <div className="app">
            <div className={'mint-container'}>
                <NavBar accounts={accounts} setAccounts={setAccounts}></NavBar>
                <MainMint accounts={accounts} setAccounts={setAccounts}></MainMint>
            </div>

        </div>
    );
}

export default App;
