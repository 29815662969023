import { useState } from "react";
import { ethers,BigNumber } from "ethers";
import desertTigers from './contract-abi.json'

const desertTigersAddress = '0x7B1fC6439B8118eA47B7f88e6d7Ae789CDE0b167';

const MainMint  = ({accounts,setAccounts}) => {
    const [mintAmount,setMintAmount] = useState(1);
    const isConnected = Boolean(accounts[0]);


    async function handleMint(){
        if(window.ethereum){
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(desertTigersAddress,desertTigers.abi,signer); 
            
        
            try {
                    const response = await contract.publicSaleMint(BigNumber.from(mintAmount),{gasLimit: 500000, value: ethers.utils.parseEther((0.2 * mintAmount).toString())});
                    console.log(response);
                
            } catch(error){
                console.log(error)
            }
        }
    }

    const handleDecrement = () =>{
        if(mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);

    }

    const handleIncrement = () => {
        setMintAmount(mintAmount + 1);
    }

    return(
        <div>
            <h1>Desert Tigers</h1>
            {isConnected ? (
                <div>
                    <div className={'control-container'}>
                        <button className={'btn-mint'} onClick = {handleDecrement}>-</button>
                        <input className={'mint-amount'} type="number" value={mintAmount}/>
                        <button className={'btn-mint'} onClick = {handleIncrement}>+</button>

                    </div>
                    <button className={'btn margin-top'} onClick = {handleMint}>Mint</button>
                </div>
            ): (
                <p>Connect to Mint</p>
            )}
        </div>
    )
}

export default MainMint;

